import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--my-pos-make-requests-form"
export default class extends Controller {
  connect() {
    $(this.element).find('.datepicke').datepicker({language: 'fr', autoclose: true}).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top);
    });
  }
}
